<template>
  <div class="col-lg-3 col-md-6 col-sm-6">
    <div class="card card-stats">
      <div class="card-body">
        <div class="row">
          <div class="col-5 col-md-4">
            <div class="icon-big text-center text-success">
              <el-tooltip
                :content="txt.pendingapproval"
                :open-delay="100"
                v-if="vps.status == 'approve'"
                placement="top">
                <p-button size="md" type="default" v-if="vps.status == 'approve'">
                  <i class="fa fa-desktop statusIcon"></i>
                </p-button>
              </el-tooltip>

              <el-tooltip
                :content="txt.offline"
                :open-delay="100"
                v-if="vps.status == 'creating'"
                placement="top">
                <p-button size="md" type="warning" v-if="vps.status == 'creating'">
                  <i class="fa fa-cog fa-spin fa-fw statusIcon"></i>
                </p-button>
              </el-tooltip>

              <el-tooltip
                :content="txt.offline"
                :open-delay="100"
                v-if="vps.status != 'running' && vps.status != 'approve' && vps.status != 'creating'"
                placement="top">
                <p-button size="md"
                          type="danger"
                          v-if="vps.status != 'running' && vps.status != 'approve' && vps.status != 'creating'">
                  <i class="fa fa-desktop statusIcon"></i>
                </p-button>
              </el-tooltip>

              <el-tooltip
                :content="txt.active"
                :open-delay="100"
                v-if="vps.status == 'running'"
                placement="top">
                <p-button size="md"
                          type="success"
                          v-on:click="openrdp()"
                          v-if="vps.status == 'running'">
                  <i class="fa fa-desktop statusIcon"></i>
                </p-button>
              </el-tooltip>

            </div>
          </div>
          <div class="col-7 col-md-8">

            <div class="numbers" v-if="vps.status != 'approve'">
              <p class="card-category">
                <el-tooltip
                  :content="vps.billplan"
                  :open-delay="100"
                  placement="top">
                 <span>
                   {{ vps.specs }}
                 </span>
                </el-tooltip>
              </p>
              <p class="card-category">
                {{ vps.billplan}}
              </p>
              <p class="card-title">
                {{ vps.name }}
              </p>
              <p class="small">
                <img :src="locationIcon"/>
              </p>

<!--              <monitoring-->
<!--                slot="button"-->
<!--                v-if="vps.monitor || !vps.whitelabel"-->
<!--                :monitorId="vps.monitor"-->
<!--                v-on:click="modalMonitor(vps)"-->
<!--                :status="vps.status == 'running'">-->
<!--              </monitoring>-->

            </div>

            <div class="numbers" v-if="vps.status == 'approve'">
              <p class="card-category">
                <el-tooltip
                  :content="vps.billplan"
                  :open-delay="100"
                  placement="top">
                 <span>
                   {{ vps.specs }}
                 </span>
                </el-tooltip>
              </p>
              <p class="card-category">
                {{ vps.userid }}
              </p>
              <p v-if="!isAdmin" class="card-category" style="margin-top: 45px">
                {{ $t('pleasewaitapproval') }}
              </p>
              <p v-if="isAdmin" class="card-category" style="margin-top: 45px">
                <b>{{ $t('doyouapprovevps') }}</b>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer" v-if="vps.status != 'creating' && vps.status != 'approve'">
        <hr>

        <div class="pull-left">

          <el-tooltip :content="vps.whitelabel" v-if="vps.whitelabel" :open-delay="100" placement="top">
            <div class="broker-logo" v-if="vps.whitelabel">
              <img :src="`/static/img/brokerlogos/${vps.whitelabel}.png`"></img>
            </div>
          </el-tooltip>

          <el-tooltip content="Hokocloud" v-else :open-delay="100" placement="top">
            <div class="broker-logo" v-if="!vps.whitelabel">
              <img :src="vpsLogo"></img>
            </div>
          </el-tooltip>

<!--          <el-tooltip :content="location" :open-delay="100" placement="top">-->
<!--            <img :src="locationIcon" />-->
<!--          </el-tooltip>-->

          <el-tooltip :content="vps.userid" :open-delay="100" placement="top">
            <p-button class="icon-btn" type="icon" link v-if="eml != vps.userid">
              <i class="fa fa-user-circle-o fa-2x"></i>
            </p-button>
          </el-tooltip>

        </div>
        <div class="pull-right" v-if="!loading">

          <el-tooltip :content="txt.info" :open-delay="100" placement="top">
            <p-button
              v-if="this.vps.userid == this.eml"
              class="icon-btn" type="icon" link v-on:click="showVPSInfo()">
              <i class="fa fa-info fa-2x"></i>
            </p-button>
          </el-tooltip>

          <el-tooltip :content="txt.resetpass" :open-delay="100" placement="top">
            <p-button class="icon-btn" type="icon" link v-on:click="resetPass()">
              <i class="fa fa-key fa-2x"></i>
            </p-button>
          </el-tooltip>

          <el-tooltip :content="txt.reboot" :open-delay="100" placement="top">
            <p-button class="icon-btn" type="icon" link v-on:click="rebootVPS()">
              <i class="fa fa-refresh fa-2x"></i>
            </p-button>
          </el-tooltip>

          <el-tooltip :content="txt.stop" :open-delay="100" placement="top">
            <p-button class="ml-1 icon-btn" type="icon" link v-on:click="stopVPS()">
              <i class="fa fa-power-off fa-2x"></i>
            </p-button>
          </el-tooltip>

          <el-tooltip :content="txt.deletevps" :open-delay="100" placement="top">
            <p-button
              class="ml-1 icon-btn"
              type="icon"
              link
              v-on:click="deleteVPSWarning()">
              <i class="fa fa-trash-o fa-2x"></i>
            </p-button>
          </el-tooltip>

        </div>
        <div class="pull-right" v-if="loading">
          <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda;"></i>
        </div>
      </div>

      <div class="card-footer" v-if="vps.status == 'creating'">
        <hr>
        <b>{{ $t('vpsbeingcreated') }}</b>
      </div>

      <div class="card-footer" v-if="vps.status == 'approve' && ((isWlAdmin && vps.whitelabel == adminWhitelabel) || isAdmin)">
        <hr>

        <div class="pull-left">
          <el-tooltip :content="vps.whitelabel" v-if="vps.whitelabel" :open-delay="100" placement="top">
            <div class="broker-logo" v-if="vps.whitelabel">
              <img :src="`/static/img/brokerlogos/${vps.whitelabel}.png`"></img>
            </div>
          </el-tooltip>

          <el-tooltip content="Hokocloud" v-else :open-delay="100" placement="top">
            <div class="broker-logo" v-if="!vps.whitelabel">
              <img :src="vpsLogo"></img>
            </div>
          </el-tooltip>
        </div>

        <div class="pull-right">

          <span></span>

          <el-tooltip :content="txt.details" :open-delay="100" placement="top">
            <p-button class="ml-1 icon-btn" type="icon" link v-if="!!vps.details" v-on:click="showDetails(vps.details)">
              <i class="fa fa-info-circle fa-2x"></i>
            </p-button>
          </el-tooltip>

          <el-tooltip :content="txt.approve" :open-delay="100" placement="top">
            <p-button class="ml-1 icon-btn" type="icon" link v-on:click="approveFunction(vps)">
              <i class="fa fa-thumbs-up fa-2x"></i>
            </p-button>
          </el-tooltip>

          <el-tooltip :content="txt.disapprove" :open-delay="100" placement="top">
            <p-button class="ml-1 icon-btn" type="icon" link v-on:click="disapproveFunction(vps)">
              <i class="fa fa-thumbs-down fa-2x"></i>
            </p-button>
          </el-tooltip>

        </div>
      </div>

      <div class="card-footer" v-if="vps.status == 'approve' && !isAdmin && !isWlAdmin">
        <hr>
        <div class="pull-left">
          <el-tooltip :content="vps.whitelabel" v-if="vps.whitelabel" :open-delay="100" placement="top">
            <div class="broker-logo" v-if="vps.whitelabel">
              <img :src="`/static/img/brokerlogos/${vps.whitelabel}.png`"></img>
            </div>
          </el-tooltip>

          <el-tooltip content="Hokocloud" v-else :open-delay="100" placement="top">
            <div class="broker-logo" v-if="!vps.whitelabel">
              <img :src="vpsLogo"></img>
            </div>
          </el-tooltip>

<!--          <el-tooltip :content="location" :open-delay="100" placement="top">-->
<!--            <img :src="locationIcon" style="margin-top: 12px"/>-->
<!--          </el-tooltip>-->

        </div>
        <div class="pull-right">
          <el-tooltip :content="txt.deletevps" :open-delay="100" placement="top">
            <p-button class="ml-1 icon-btn" type="icon" link v-on:click="disapproveFunction(vps)">
              <i class="fa fa-trash-o fa-2x"></i>
            </p-button>
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import monitoring from "./monitoring";
  import Constants from 'src/assets/constants'
  import {Tooltip} from 'element-ui'
  import swal from 'sweetalert2'

  const rules = Constants.methods.getRules()
  const user_data = JSON.parse(localStorage.getItem('user-info'));

  export default {
    name: "vps",
    components: {
      [Tooltip.name]: Tooltip,
      monitoring,
    },
    props: {
      reference: {
        type: [Object],
        description: "VPS data",
        default: {
          id: 1,
          details: null,
          billplan: null,
          dns: null,
          location: null,
          monitor: null,
          name: null,
          pswd: null,
          status: null,
          userid: null,
          vultrid: null,
          whitelabel: null
        }
      },
      wlLogos: {
        type: Array,
        description: 'Whitelabel logos',
        default: []
      },
      modalMonitor: {
        type: Function,
        description: 'Modal monitor management'
      },
      approveFunction: {
        type: Function,
        description: 'Approve function'
      },
      disapproveFunction: {
        type: Function,
        description: 'Approve function'
      },
      vpsinfo: {
        type: Function,
        description: 'Info function'
      },
      confirmDeleteModal: {
        type: Function,
        description: 'Confirm deletion function'
      }
    },
    data() {
      return {
        isWhitelabelVps: false,
        eml: user_data.email,
        loading: false,
        txt: {
          active: this.$t('activevps'),
          info: this.$t('vpsinfo'),
          reboot: this.$t('reboot'),
          stop: this.$t('stop'),
          deletevps: this.$t('deletevps'),
          resetpass: this.$t('resetpass'),
          approve: this.$t('approve'),
          disapprove: this.$t('disapprove'),
          pendingapproval: this.$t('pendingapproval'),
          offline: this.$t('nonactivevps'),
          details: this.$t('checkdetails')
        },
        vps_locations: [
          {
            "code": "nyc",
            "description": "New York",
            "icon": "US"
          },
          {
            "code": "ams",
            "description": "Amsterdam",
            "icon": "NL"
          },
          {
            "code": "lon",
            "description": "London",
            "icon": "GB"
          },
          {
            "code": "fra",
            "description": "Frankfurt",
            "icon": "DE"
          },
          {
            "code": "tyo",
            "description": "Tokyo",
            "icon": "JP"
          },
          {
            "code": "sin",
            "description": "Singapore",
            "icon": "SG"
          }
        ],
        location: '',
        locationIcon: '',
        dir: '/static/img/flags/',
        vps: {},
        isAdmin: rules.isAdmin,
        adminWhitelabel: rules.userWhitelabel,
        isWlAdmin: this.reference.whitelabel == rules.userWhitelabel && rules.hasWlRole,
        hokoAdmin: rules.isAdmin,
        addons: {
          monitor: false
        },
        logo: '/static/img/logosidebar.png'
      }
    },
    computed: {
      vpsLogo() {
        return this.logo
      }
    },
    methods: {
      applyLocation() {
        let local = this.vps_locations[0];
        try {
          const vpslocal = this.vps.location.toLowerCase();
          local = this.vps_locations.filter(item => {
            if (vpslocal == 'new jersey') {
              return item.code == 'nyc';
            }
            return item.description.toLowerCase() == vpslocal || item.code.toLowerCase() == vpslocal
          })[0]
        } catch (e) {
        }
        this.location = local.description;
        this.locationIcon = this.dir + local.icon + ".png";
      },
      applyLogo() {
        let local = this.wlLogos.filter(item => item.whitelabel == this.vps.whitelabel)
        if (local.length) {
          this.logo = local[0].logo
        }
      },
      showVPSInfo() {
        this.vpsinfo(this.vps)
      },
      resetPass() {
        this.loading = true;
        const doneop = resp => {
          this.$toast.success(this.$t('resetpasssuccess', [this.vps.name] ));
          this.$emit('getVpsList');
          this.loading = false;
        };
        const failop = error => {
          this.$toast.error(this.$t('failtoresetvpspassword'));
          this.loading = false;
        };
        if(this.vps.whitelabel) {
          this.$resetWhitelabelRDP(this.vps.name).then(doneop, failop);
        } else {
          this.$resetRDP(this.vps.name).then(doneop, failop);
        }
      },
      rebootVPS() {
        this.loading = true;
        const doneop = resp => {
          this.$toast.success(this.$t('rebootsuccess', [this.vps.name]));
          this.loading = false;
        };
        const failop = error => {
          this.$toast.error(this.$t('VM_COULD_NOT_REBOOT'));
          this.loading = false;
        };
        if (this.isWhitelabelVps) {
          this.$rebootWhitelabelVPS(this.vps.name).then(doneop, failop);
        } else {
          this.$manageVPS(this.vps.name, 'reboot').then(doneop, failop);
        }
      },
      stopVPS() {
        this.loading = true;
        const doneop = resp => {
          this.$toast.success(this.$t('stoppedvps', [this.vps.name]));
          this.loading = false;
        };
        const failop = error => {
          this.$toast.error(this.$t('failtostopvps'));
          this.loading = false;
        };
        if (this.isWhitelabelVps) {
          this.$stopWhitelabelVPS(this.vps.name).then(doneop, failop);
        } else {
          this.$manageVPS(this.vps.name, 'stop').then(doneop, failop);
        }
      },
      deleteVPSWarning() {

        this.loading = true;
        this.confirmDeleteModal(
          this.confirmDeleteVPS,
          this.cancelDeleteVPS
        );
      },
      confirmDeleteVPS() {
        const self = this;
        return new Promise((resolve, reject) => {
          if (this.isWhitelabelVps) {
            self.$deleteWhitelabelVPS(this.vps.name)
              .then(resp => {
                self.loading = false;
                resolve(resp);
              }, resp => {
                self.loading = false;
                reject(resp);
              })
          } else {
            self.$manageVPS(self.vps.name, 'delete')
              .then(resp => {
                self.loading = false;
                resolve(resp);
              }, resp => {
                self.loading = false;
                reject(resp);
              })
          }
        })
      },
      cancelDeleteVPS() {
        this.loading = false;
      },
      openrdp() {
        if (this.vps.status != 'running') {
          this.$toast.warning(this.$t('vpsisnotrunning'));
          return
        }
        if (this.vps.userid != this.eml) {
          this.$toast.warning(this.$t('notauthorizedtoaccessvps'));
          return
        }
        window.open("/webrdp/"+ this.vps.name);
      },
      showDetails(details) {
        swal({
          title: this.$t('checkdetails'),
          text: details,
          confirmButtonClass: 'btn btn-success'
        })
      }
    },
    mounted() {
      this.vps = this.reference;
      this.isWhitelabelVps = !!this.vps.whitelabel;
      this.applyLogo()
      this.addons.monitor = this.vps.addons ? this.vps.addons.includes('MONITOR') : false;
      this.applyLocation();
    },
    watch: {
      'reference': function (newVal) {
        this.vps = newVal;
      }
    }
  }
</script>
<style scoped lang="scss">
  .icon-btn:hover {
    background-color: #fff !important;
  }
  .icon-btn:hover i.fa-trash-o, .icon-btn:hover i.fa-thumbs-down {
    color: red !important;
  }
  .icon-btn:hover i.fa-thumbs-up {
    color: #40cb00 !important;
  }
  .icon-btn:hover i {
    color: #51bcda !important;
  }
  .statusIcon {
    line-height: normal !important;
  }
  .card-category {
    font-size: 0.35em !important;
  }
  .card-title {
    font-size: 0.65em;
  }
  .broker-logo {
    float: left;
    top: -5px;
    position: relative;
    padding: 10px 5px 0 3px;
  }
  .broker-logo::v-deep img {
    border-radius: 50%;
    width: 33px;
    border: 1px solid silver;
  }
</style>
