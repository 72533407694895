<template>
  <modal :required="true" :show.sync="modalMonitor" headerClasses="justify-content-center">
    <template></template>
    <h5 slot="header" class="modal-title">
      {{ $t('premiumopts') }}
    </h5>
    <template v-if="!submitting">
      <div>
        <h5>{{ $t('trackvpsfromcrash') }}</h5>
        <div class="row">
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>{{ $t('rebootlabeltxt') }}</label>
              <select class="form-control" v-model="monitor.reboot">
                <option value="0">{{ $t('donotreboottxt') }}</option>
                <option value="5">5 Min</option>
                <option value="10">10 Min</option>
                <option value="20">20 Min</option>
                <option value="30">30 Min</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="submitting">
      <div class="text-center">
        <h2 v-if="!loading">{{ $t('submitting') }}</h2>
        <h2 v-if="loading">{{ $t('loadingtxt') }}</h2>
        <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
      </div>
    </template>

    <template slot="footer"  v-if="!submitting">

      <div class="left-side">
        <p-button type="success" v-on:click="send_monitor()" link>
          <span v-if="vps.whitelabel || vps.monitor">
            {{ $t('upentrybtn') }}
          </span>
          <span v-else>
            {{ $t('create') }}
          </span>
        </p-button>
      </div>

      <div class="divider" v-if="!vps.whitelabel && vps.monitor"></div>

      <div class="left-side" v-if="!vps.whitelabel && vps.monitor">
        <p-button type="danger" v-on:click="cancelService()" link>
          <span>
            {{ $t('cancelservice') }}
          </span>
        </p-button>
      </div>

      <div class="divider"></div>

      <div class="right-side">
        <p-button type="danger" v-on:click="modalMonitor = false" link>
          {{ $t('cancel') }}
        </p-button>
      </div>
    </template>

    <template slot="footer" v-if="submitting">
      <div class="left-side">
        <p-button type="default" link>
         <span v-if="vps.whitelabel || vps.monitor">
            {{ $t('upentrybtn') }}
          </span>
          <span v-else>
            {{ $t('create') }}
          </span>
        </p-button>
      </div>
      <div class="divider" v-if="!vps.whitelabel && vps.monitor"></div>
      <div class="left-side" v-if="!vps.whitelabel && vps.monitor">
        <p-button type="default" link>
          <span>
            {{ $t('cancelservice') }}
          </span>
        </p-button>
      </div>
      <div class="divider"></div>
      <div class="right-side">
        <p-button type="default" link>
          {{ $t('cancel') }}
        </p-button>
      </div>
    </template>

  </modal>
</template>
<script>
  import { Modal } from 'src/components/UIComponents'

  export default {
    name: "modal_monitor",
    components: {
      Modal,
    },
    props: {
      refresh: {
        type: Function,
        description: 'Refresh page'
      },
    },
    data() {
      return {
        isWlVps: false,
        submitting: false,
        loading: false,
        addonValue: [],
        vps: {},
        modalMonitor: false,
        monitor: {}
      }
    },
    methods: {
      send_monitor() {
        this.submitting = true;

        if (this.isWlVps) {
          this.$update_wl_vps_monitor({
            id: this.vps.monitor,
            reboot: this.monitor.reboot,
            email: this.monitor.email,
            vm_id: this.vps.id,
            vm_name: this.vps.name,
            dns: this.vps.dns,
          }).then(this.success_handler, this.error_handler)
        } else {
          this.$manage_monitor({
            id: this.vps.monitor,
            reboot: this.monitor.reboot,
            email: this.monitor.email,
            vm_id: this.vps.id,
            vm_name: this.vps.name,
            dns: this.vps.dns,
          }).then(this.success_handler, this.error_handler)
        }
      },
      success_handler(data) {
        this.submitting = false;
        if (!data.success) {
          return;
        }
        if (this.vps.monitor) {
          this.$toast.success(this.$t('SUCCESS_MONITOR_UPDATED'));
        } else {
          this.$toast.success(this.$t('SUCCESS_MONITOR_CREATED', [this.vps.name]));
        }
        this.modalMonitor = false;
        this.refresh();

      },
      error_handler(error) {
        this.submitting = false;
        this.$toast.error(this.$t('somethingwentwrong'));
      },
      show(vps) {
        this.vps = vps;
        this.isWlVps = !!vps.whitelabel;
        this.modalMonitor = true;

        this.populateMonitor()

        if (!this.vps.monitor) {
          return;
        }

        this.submitting = true;
        this.loading = true;

        if (this.isWlVps) {
          this.$retrieve_monitor(this.vps.monitor)
            .then(this.loadMonitor, this.failLoad)
        } else {
          this.$get_monitor(this.vps.monitor)
            .then(this.loadMonitor, this.failLoad)
        }
      },
      populateMonitor() {
        this.monitor = {
          email: this.vps.userid,
          reboot: 5
        };
      },
      loadMonitor(resp) {
        this.submitting = false;
        this.loading = false;
        if (!resp.success) {
          this.failLoad();
          return;
        }
        if (!this.isWlVps && resp.data) {
          this.monitor.id = resp.data[0].monitor;
          this.monitor.email = resp.data[0].email;
          this.monitor.reboot = resp.data[0].reboot;
        } else {
          this.monitor.id = resp.data.monitor.monitor;
          this.monitor.email = resp.data.monitor.email;
          this.monitor.reboot = resp.data.monitor.reboot;
        }
      },
      failLoad() {
        this.submitting = false;
        this.loading = false;
      },
      successHandler(resp) {
        if (!resp.success) {
          return;
        }
        this.addonValue = resp.data.map(addon => { return parseFloat(addon.cost).toFixed(2); })
      },
      errorHandler() {
      },
      cancelService() {
        if (this.isWlVps) {
          return;
        }
        this.submitting = true;
        this.$delete_monitor({ monitor: this.vps.monitor })
          .then(this.successDeleteHandler, this.errorDeleteHandler)
      },
      successDeleteHandler(resp) {
        if (!resp.success) {
          return
        }
        this.submitting = false;
        this.$toast.success(this.$t('SUCCESS_MONITOR_DELETED'));
        this.modalMonitor = false;
        this.refresh();
      },
      errorDeleteHandler(resp) {
        this.submitting = false;
      },
    },
    mounted() {
      this.$getVPSPlans_v2({type:'Addons'})
        .then(this.successHandler, this.errorHandler);
    }
  }
</script>
<style scoped>
</style>
