<template>
  <button @click="handleClick"
          class="btn btn-pin btn-fill"
          v-bind:class="{'btn-info': !monitorId, 'btn-warning': !!monitorId}">
    <i class="fa fa-tv"></i>
    <span v-if="!!monitorId"> {{ $t('monitored') }}</span>
    <span v-if="!monitorId"> {{ $t('vpsaddonsadd') }}</span>
  </button>
</template>
<script>
  export default {
    name: 'monitoring',
    props: {
      monitorId: {
        type: Number,
        description: 'Monitor Id'
      },
      status: {
        type: Boolean,
        description: true
      },
    },
    data() {
      return {
      }
    },
    methods: {
      handleClick(evt) {
        this.$emit('click', evt)
      }
    }
  }
</script>
<style>
  button.btn.btn-pin.btn-fill {
    color: white !important;
    font-size: 12px !important;
    padding: 5px;

  }
</style>
