<template>
  <module
    ref="module"
    id="vpsList"
    titleIcon="fa fa-cubes"
    :title="$t('myvpss')"
    :use-default-list="false"
    >
    <div slot="toolbar-global">
      <el-tooltip :content="$t('refreshAccounts')" placement="top">
        <p-button class="mr-1" type="default" v-on:click="refreshList()">
          <i class="fa fa-refresh">
            <div class="cooldown"></div>
          </i>
        </p-button>
      </el-tooltip>
    </div>
    <div slot="global" class="col-md-12">
      <modal :show.sync="modalvpscreds"
             footerClasses="justify-content-center"
             type="notice">
        <h5 slot="header" class="modal-title">{{ $t('vpsinfo') }}</h5>
        <template>
          <div class="instruction text-center">
            <div class="row">
              <div class="col-md-12">
                <i class="fa fa-server"></i> {{ vpscreds.dns }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <i class="fa fa-user"></i> {{ vpscreds.username }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <i class="fa fa-key"></i> {{ vpscreds.pwd }}
              </div>
            </div>
          </div>
        </template>
        <div slot="footer" class="justify-content-center">
          <p-button type="info" round @click.native="modalvpscreds = false">{{ $t('gotit') }}</p-button>
        </div>
      </modal>

      <modal :required="true" :show.sync="modalvpswarn" headerClasses="justify-content-center" >
            <h4 slot="header" class="title title-up">{{ $t('deletevpswarn') }}</h4>
            <p v-if="!loadingDeletion">{{ $t('deletevpswarntxt') }}</p>

            <div v-if="loadingDeletion" class="text-center">
              <h2>{{ $t('submitting') }}</h2>
              <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
            </div>

            <template v-if="!loadingDeletion" slot="footer">
              <div class="left-side">
                <p-button v-on:click="confirmDeleteVPS()" link>
                  {{ $t('continue_text') }}
                </p-button>
              </div>
              <div class="divider"></div>
              <div class="right-side">
                <p-button type="danger" v-on:click="cancelDeleteVPS()" link>
                  {{ $t('back') }}
                </p-button>
              </div>
            </template>
            <template v-if="loadingDeletion" slot="footer">
              <div class="left-side">
                <p-button link>
                  {{ $t('continue_text') }}
                </p-button>
              </div>
              <div class="divider"></div>
              <div class="right-side">
                <p-button link>
                  {{ $t('back') }}
                </p-button>
              </div>
            </template>
          </modal>

      <modal :show.sync="confirmation" headerClasses="justify-content-center">
        <h4 slot="header" class="title title-up">
          {{ $t('needconfirmation') }}
        </h4>
        <div v-if="!submitError && !submiting" class="col-12 text-center">

          <span v-if="op == 'approve'">
            {{ $t('confirmnewwhitelabelvps', [vps.billplan, vps.userid]) }}
          </span>
          <div v-if="op == 'disapprove'">
            <span>
              {{ $t('confirmwlvpsdisapprove')}}
            </span>
            <textarea
              v-if="isAdmin || (vps.whitelabel == adminWhitelabel && userHasWlRole)"
              v-model="rejection_justification"
              class="form-control px-2 mt-2"
              maxlength='400'
              :placeholder="$t('disapprovedvpsjustification')"
              rows="3"></textarea>
          </div>
        </div>
        <div v-if="!submitError && submiting" class="text-center">
          <h2>{{ $t('submitting') }}</h2>
          <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
        </div>
        <div v-if="submitError">
          <div class="col-md-12 text-center">
            <i class="fa fa-thumbs-down fa-huge text-danger"></i>
          </div>
          <div class="col-md-12 text-center" style="margin-top: 20px">
            <b>{{ $t(errorMsg) }}</b>
          </div>
        </div>

        <template slot="footer" v-if="!submitError && !submiting">
          <div class="left-side">
            <p-button type="success" v-on:click="submit()" link>
                <span>
                  {{ $t('yestxt') }}
                </span>
            </p-button>
          </div>
          <div class="divider"></div>
          <div  class="right-side">
            <p-button type="warning" v-on:click="confirmation = false" link>
              {{ $t('notxt') }}
            </p-button>
          </div>
        </template>
        <template slot="footer" v-if="!submitError && submiting">
          <div class="left-side">
            <p-button type="default" link>
              <span>
                {{ $t('yestxt') }}
              </span>
            </p-button>
          </div>
          <div class="divider"></div>
          <div  class="right-side">
            <p-button type="default" link>
              {{ $t('notxt') }}
            </p-button>
          </div>
        </template>
        <template slot="footer" v-if="submitError">
          <p-button type="default" v-on:click="confirmation = false" link>
            <span>
              {{ $t('confirm') }}
            </span>
          </p-button>
        </template>
      </modal>

      <div class="row" v-if="isWhitelabelAdmin || isAdmin">
        <div class="col-lg-2">
          <fg-input
            :placeholder="txt.searchtxt"
            v-model="vpsFilter.param"
            addon-right-icon="nc-icon nc-zoom-split">
          </fg-input>
        </div>

        <div class="col-lg-1">
           <p-button type="info" style="margin-top: 0;" v-on:click="applyFilter()" >{{ txt.applyfiltertxt }}</p-button>
        </div>
      </div>


      <div v-if="inprogress" class="col-md-12 text-center">
        <h2>{{ $t('loadingvps') }}</h2>
        <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
      </div>

      <div class="row" v-else>
        <div class="col-md-12" v-if="vpsApprovalList.length > 0">
            <h5>{{ $t('pendingapproval') }}</h5>
          <div class="row">
            <vps v-for="(vps, index) in vpsApprovalList"
                 :key="index"
                 :approveFunction="approve"
                 :disapproveFunction="disapprove"
                 :wlLogos="whitelabelLogos"
                 :reference="vps">
            </vps>
          </div>
        </div>

        <div class="col-md-12" v-if="vpsList.length > 0">
          <h5>{{ $t('ownedvps') }}</h5>
        </div>

        <vps v-for="(vps, index) in vpsList"
             :key="index"
             :confirmDeleteModal="confirmDeleteModal"
             :vpsinfo="showVpsInfo"
             :modalMonitor="showModalMonitor"
             @getVpsList="applyFilter"
             :wlLogos="whitelabelLogos"
             :reference="vps">
        </vps>

        <div v-if="(isWlSession  && vpsList.length == 0 && vpsApprovalList == 0) || (!isWlSession && vpsList.length  == 0)">
          <div class="row">
            <div class="col-md-12" v-if="isWlSession && canrequest">
              <div class="callout callout-success">
                <h5>{{ $t('novpswl') }}</h5>
                <p-button type="success"
                          v-on:click="requestvps()">
                  {{ $t('requestvps') }}
                </p-button>
              </div>
            </div>

            <div class="col-md-12" v-if="canbuyvps && vpsApprovalList.length == 0 && vpsList.length == 0 ">
              <div class="callout callout-primary">
                <h5>{{ $t('novpsshko') }}</h5>
                <p-button type="primary"
                          v-on:click="buyvps()">
                  {{ $t('buyvps') }}
                </p-button>
              </div>
            </div>

          </div>
        </div>

        <modal_monitor
          ref="modalMonitor"
          :refresh="listVPS">
        </modal_monitor>

      </div>
    </div>
  </module>
</template>

<script>
  import Constants from 'src/assets/constants';
  import {Modal} from "@/components/UIComponents";
  import vps from './Vps.vue';
  import modal_monitor from './modal_monitor.vue';

  const rules = Constants.methods.getRules()
  const user_data = JSON.parse(localStorage.getItem('user-info'));

  export default {
    name: "VpsList",
    components: {
      vps,
      Modal,
      modal_monitor
    },
    data () {
      return {
        isWlSession: rules.isWhitelabelSession,
        canbuyvps: !rules.isBroker && (rules.check('/vps/buy') || rules.isAdmin || rules.isWhitelabelAdmin),
        canrequest: rules.check('/vps/request'),
        ueml: user_data.email,
        vpsList: [],
        vpsApprovalList: [],
        whitelabelLogos: [],
        txt: {
          searchtxt: this.$t('searchtxt'),
          whitelabeltxt: this.$t('choosewhitelabel'),
          applyfiltertxt: this.$t('applyfilter'),
        },
        vpsFilter: {
          param: '',
          whitelabel: ''
        },
        whitelabelList: [],
        inprogress: true,
        modalvpscreds: false,
        modalvpswarn: false,
        loadingDeletion: false,
        vpscreds: {
          'dns': null,
          'pwd': null,
          'username': null
        },
        op: null,
        rejection_justification: '',
        submiting: false,
        submitError: false,
        confirmation: false,
        whitelabel_logos: [],
        userRoles: rules.roles,
        wlAdmin: false,
        adminWhitelabel: rules.userWhitelabel,
        userHasWlRole: rules.hasWlRole
      }
    },
    computed: {
      isAdmin() {
        return rules.isAdmin;
      },
      isWhitelabelAdmin() {
        return rules.isWhitelabelAdmin;
      },
      currentWhitelabel() {
        return rules.whitelabel
      }
    },
    methods: {
      loadVPSs(response) {
        this.inprogress = false
        if (response.success) {
          const self = this;
          new Promise(function (resolve) {
            self.vpsList = [];
            self.vpsApprovalList = [];
            resolve();
          }).then(function () {
            self.vpsList = response.data.vms;
            self.vpsApprovalList = response.data.approval;
          })
        }
      },
      loadLogos(response){
        if (response.success) {
          const self = this;
          new Promise(function (resolve) {
            self.vpsList = [];
            resolve();
          }).then(function () {
            self.whitelabelLogos = response.data;
          })
        }
      },
      showVpsInfo(stats) {
        this.vpscreds = {
          'dns': stats.dns,
          'pwd': stats.pswd,
          'username': stats.username
        };
        this.modalvpscreds = true;
      },
      failop(response) {
        this.$toast.error('Fail to retrieve VPS list')
        this.inprogress = false
      },
      applyFilter() {
        this.inprogress = true
        this.$getVPSes_v3(this.vpsFilter.param)
          .then(this.loadVPSs, this.failop)
      },
      showModalMonitor (vps) {
        this.$refs.modalMonitor.show(vps);
      },
      wlCountUpdate () {
      },
      requestvps() {
        window.location = '/vps/request'
      },
      buyvps() {
        window.location = '/vps/buy'
      },
      confirmDeleteModal(callbackConfirm, callbackCancel) {
        this.confirmDeleteVPS = () => {
          this.loadingDeletion = true;
          callbackConfirm()
            .then(this.deleteSuccessHandler, this.deleteErrorHandler)
        };
        this.cancelDeleteVPS = () => {
          this.modalvpswarn = false;
          callbackCancel();
        }
        this.modalvpswarn = true;
      },
      deleteSuccessHandler(resp) {
        this.modalvpswarn = false;
        this.loadingDeletion = false;
        this.$toast.success(this.$t('vpsdeletedsuccesfuly'));
        this.listVPS()
      },
      deleteErrorHandler() {
        this.modalvpswarn = false;
        this.loadingDeletion = false;
        this.$toast.error(this.$t('failtodeletevps'));
      },
      approve(vps) {
        this.op = 'approve';
        this.vps = vps;
        this.submitError = false;
        this.confirmation = true
      },
      disapprove(vps) {
        this.rejection_justification = '';
        this.op = 'disapprove';
        this.vps = vps;
        this.submitError = false;
        this.confirmation = true
      },
      submit() {
        if (this.isAdmin || (this.vps.whitelabel == this.adminWhitelabel && this.userHasWlRole)) {
          if (this.rejection_justification.length < 5 && this.op == 'disapprove' ) {
            this.$toast.warning(this.$t('minimaldisapprovemessage'))
            return;
          }
          this.submiting = true;
          this.$vpsWhitelabelResolve({
            vps: this.vps.id,
            action: this.op,
            whitelabel: this.vps.whitelabel,
            rejection_justification: this.rejection_justification,
          }).then(this.approveSuccessHandler, this.approveErrorHandler)
        } else {
          this.submiting = true;
          this.$vpsWhitelabelUserDisapprove({
            vps: this.vps.id,
            userid: this.ueml
          }).then(this.approveSuccessHandler, this.approveErrorHandler)
        }
      },
      approveSuccessHandler(response) {
        this.submiting = false;
        if (!response.success) {
          this.submitError = true;
          return
        }
        this.confirmation = false;

        this.$getVPSes_v3()
          .then(this.loadVPSs, this.failop)
      },
      approveErrorHandler() {
        this.submiting = false;
        this.submitError = true;
      },
      listVPS() {
        this.$getVPSes_v3().then(this.loadVPSs, this.failop)
      },
      refreshList() {
        this.inprogress = true;
        this.listVPS()
      }
    },
    mounted() {
      if (this.userRoles.includes("wladmin")) {
        this.wlAdmin = true
      }
      this.inprogress = true
      const self = this;

      new Promise(function (resolve) {
        const success = resp => {
          self.loadLogos(resp);
          resolve();
        };
        const error = resp => {
          self.failop();
          resolve();
        };
        self.$getWlLogoList().then(success, error)

      }).then(function () {
        self.listVPS()
      })
    }
  }

</script>

<style scoped>

</style>
